.dialog-close-button {
    padding: 10px 20px;
    background-color: #ff0000; /*background */
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    display: inline-block;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
}

.csv-link {
    padding: 10px 20px;
    background-color: #43a047; /*background */
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    display: inline-block;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    margin-top: 25px;
}


.dialog-success-button {
    padding: 10px 20px;
    background-color: #43a047; /*background */
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    display: inline-block;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
}
